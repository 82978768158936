<template>
  <div class="donatetoknow">
    <div class="donate-title">
    <span  v-if="title">{{title}}</span>
    </div>
    <div class="needknow-container">
        <h2 class="needknowtitle">{{donatetoknow.title||Subtitle}}</h2>
        <TitleLine width="400px"></TitleLine>
        <div class="needknowcontent">
            <ul>
                <li class="content2">
                    <dd>
                    <p v-html="donatetoknow.info?donatetoknow.info:''"></p>
                    </dd>
                </li>
            </ul>
        </div>
    </div>
  </div>
</template>

<script>
import TitleLine  from '@/components/TitleLine'
export default {
  props: {
     donatetoknow: {
         type: Object,
         default: ""
     },
     title:{
         type:String,
         default:'捐献须知'
     },
     Subtitle:{
         type:String,
         defaultL:''
     }
  },
  components: {
    TitleLine
  },
  name: 'Donatetoknow'
}
</script>

<style lang="less" scoped>
.donatetoknow {
    margin-bottom: 50px;
    .donate-title  {
        margin-top: 53px;
        font-size: 23px;
        color: #333333;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        span {
            &:nth-child(1) {
            display: flex;
                &::before {
                content: "";
                display: block;
                height: 20px;
                width: 35px;
                background: url('../../../../assets/img/title.png') no-repeat center center;
                }
            }
        }
    }
    .needknow-container {
        width: 100%;
        overflow: auto;
        background: #ffffff;       
        box-shadow: 0px 0px 18px 0px rgba(14, 14, 14, 0.13);
        .needknowtitle {
            font-size: 20px;
            font-weight: bold;
            text-align: center;
            margin: 40px 0 16px 0;
        }
        .titleborder {
            height: 6px;
            background: url('../../../../assets/img/toknowborder.png') no-repeat center center;
        }
        .needknowcontent {
           width: 956px;
           min-height: 340px; 
           margin: 35px 123px 75px 121px;
           ul {
               width: 100%;
               height: 100%;
               li {
                   margin-bottom: 35px;
                   dt {
                     width: 108px;
                     height: 17px;
                     display: flex;
                     justify-content:space-between;
                     font-size: 17px;
                     color: #D1292E;
                     font-weight: bold;
                     margin-bottom: 23px;
                   }
                   dd {
                       color: #666666;
                       line-height: 36px;
                       p {
                           text-indent: 1em;
                       }
                   }
                   &:last-child {
                       margin-bottom: 0;
                   }

                   &:nth-child(1) {
                     dd {
                         p {
                             margin-top: 40px;
                             /* span {
                                 color: #D1292E;
                             } */
							 a {
								 color: blue;
								 cursor: pointer;
								 text-decoration: none;
							 }
                            &::before {
                               position: relative;
                               top: 13px;
                               left: 0px;
                               float: left;
                               content: "";
                               display: block;
                               width: 8px;
                               height: 8px; 
                               background: url('../../../../assets/img/list-little.png') no-repeat center center;
                            }
                            &:first-child {
                                text-indent: 2em;
                               &::before {
                                   display: none;
                               }
                            }
                            
                         }
                     }
                   }
               }
               .content2 {
                   p {
                       margin-top: 40px;
                       span {
                           display: block;
                       }
                   }
               }
               .paginatedonate {
                   width: 354px;
               }
           }
        }
    }
}
</style>
